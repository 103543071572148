<template>
  <div>
    <header v-if="!isLoading" class="page-header">
      <PageTitle v-if="global" :check-translations="false" :item="global" property="key"/>
      <div class="buttons">
        <button v-if="!isLoading && checkPermissions(['general.edit'])" class="btn btn-save" @click="saveGlobal()">
          <span>{{ global && global.id ? 'Globale wijzigen' : 'Globale toevoegen' }}</span>
        </button>
      </div>
    </header>

    <div class="main-content">
      <div class="pane table-pane">
        <formulate-form v-if="!isLoading" ref="globalForm" v-model="global" name="globalForm">
          <formulate-input validation="required" :options="modules" label="Module" name="module" type="select"></formulate-input>
          <formulate-input validation="required" label="Key" name="key" type="text"></formulate-input>
          <formulate-input validation="required" label="Info" name="info" type="text"></formulate-input>

          <formulate-input #default="{ index }" groupRepeatable-class="translations-group formulate-input-group-repeatable" name="translations" type="group">
            <h3>Vertaling: {{ activeLocalesBack[getLangCode(global, index)] }}</h3>
            <TranslationsRemoveButton :index="index" :item="global"/>

            <formulate-input name="lang_code" type="hidden"></formulate-input>
            <formulate-input validation="required" label="Waarde" name="value" type="tiptap"></formulate-input>
          </formulate-input>

          <TranslationsAddButtons :item="global"/>
        </formulate-form>
      </div>
    </div>
  </div>
</template>

<script>
import { ApiService } from '@/services/admin/api.service'
import { notification } from '@/notifications'
import PageTitle from '@/components/admin/PageTitle'
import { DateTime } from 'luxon'
import TranslationsAddButtons from '@/components/admin/TranslationsAddButtons'
import TranslationsRemoveButton from '@/components/admin/TranslationsRemoveButton'

export default {
  name: 'Global',
  components: {
    PageTitle,
    TranslationsAddButtons,
    TranslationsRemoveButton
  },
  data: function () {
    return {
      isLoading: true,
      module: this.$route.params.module,
      key: this.$route.params.key,
      global: null,
      modules: ['dashboard', 'loyalty', 'academy', 'products'],
      datetime: DateTime
    }
  },
  async mounted () {
    this.global = { translations: [{ lang_code: this.activeLocale }] }

    if (this.module && this.key) {
      await ApiService.fetchGlobal(this.module, this.key).then(res => {
        this.global = res.data
      })
    }

    this.isLoading = false
  },
  methods: {
    saveGlobal: async function () {
      this.$formulate.submit('globalForm')
      if (await this.$refs.globalForm.hasValidationErrors()) {
        return true
      }

      let request = null
      if (this.global.id) {
        request = ApiService.patchGlobal(this.module, this.key, this.global)
      } else {
        request = ApiService.createGlobal(this.global)
      }

      await request.then((request) => {
        if (request.status === 200) {
          if (this.global.id) {
            notification('Globale succesvol aangepast!')
          } else {
            notification('Globale succesvol toegevoegd!')
          }

          this.$router.push({ name: 'admin.globals' })
        }
      })
    }
  }
}
</script>
